@import "../../styles/variables";

.container {
  display: flex;
  padding: 1em;
  flex-direction: column;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-top: $mm;
}
.title {
  text-align: center;
  margin-bottom: 1.5em;
}
.bigContainer {
  padding: $ms;
  width: 100%;
  box-sizing: border-box;
  margin-top: $mm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 8em;
}
.BigImgCardContainer {
  box-sizing: border-box;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.BigImgCardContainer img {
  width: 100%;
  height: auto;
  border-radius: 3px;
}
.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;
}
.sectionCard {
  width: 100%;
  box-sizing: border-box;
  margin: $mm;
  text-align: center;
  border-radius: 1px;
}
.sectionCardImg {
  height: auto;
  flex-wrap: wrap;
  width: 100%;
  min-width: 222px;
}
.cardBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28%;
  height: 540px;
  min-width: 230px;
  align-items: center;
  text-align: center;
}

.cardBox a {
  text-decoration: none;
  color: $green;
}

.titleBox {
  margin-top: 1em;
  display: flex;
  text-align: center;
}
