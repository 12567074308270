.icon {
  border-radius: 80px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
}

a img:hover {
  transform: scale(1.1);
}
