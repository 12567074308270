@import "../../styles/variables";

.container {
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  margin-top: $mm;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.container p {
  max-width: 700px;
}
.inner {
  display: flex;
  max-width: 1500px;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}
.container img {
  align-self: center;
  justify-self: center;
  min-width: 280px;
  height: auto;
  max-height: 700px;
}
