@import "../../styles/variables";

.container {
  display: flex;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: $mm;
  border-radius: 6px;
  justify-content: space-between;
  align-content: center;
  box-sizing: border-box;
  padding-right: $ml;
  padding-left: $ml;
}
.container h3 {
  margin-left: 10em;
}

.logo {
  height: auto;
  min-height: 50px;
  min-width: 100px;
  max-height: 80px;
  max-width: 180px;

  justify-self: flex-start;
  align-self: center;
}
.logoContainer {
  width: 33%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.mobileLogo {
  position: absolute;
  width: 90px;
  height: auto;
  left: 20;
  top: 1.6em;
}

.navBarContainer {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.fill {
  width: 15%;
  height: 100%;
}
.link {
  text-decoration: none;
  color: #424a5f;
  font-size: 1.3em;
}

.link:hover {
  color: $green;
  cursor: pointer;
}
