@import "../../styles/variables";

.img {
  width: 100%;
  height: auto;
  min-height: 95vh;
  z-index: 10;
}

.imgH {
  max-width: 700px;
  width: 700px;
  min-width: 700px;
  max-height: 80%;
}

.legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.33);
  color: #fff;
  padding: 10px;
  text-align: center;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

.imgVert {
  height: auto;
  width: 100%;
}

.CarouselImg {
  height: 87vh;
  width: 100%;
}
.CarouselImg:hover {
  cursor: pointer;
}
