$primary: #424a5f;
$green: #00caa8;
$lightGrey: #e4ebfb;
$purple: #766eff;
$dark: #303645;
$lemon: #c7e861;
$scarlet: #9c3270;
$violer: #6414c9;
$secondary: blue;
$secondaryVariant: blue;
$background: grey;
$ms: 1em;
$mm: 2em;
$ml: 4em;
$ps: 1em;
$pm: 2em;
$pl: 4em;
