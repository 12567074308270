@import "../../../../styles/_variables.scss";

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #00caa8;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: #e4ebfb;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 2em;
  top: 2.5em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #424a5f;
  border-radius: 12px;
  justify-self: center;
  align-self: center;
}

/* 
/* Position and sizing of clickable cross button */
/*.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
/*.bm-cross {
  background: #00caa8;
}  
/* General sidebar styles */
.bm-menu {
  position: absolute;
  right: 0px;
  top: -2.67em;
  background: #424a5f;
  padding: 2.5em 1.5em 1em 1em;
  font-size: 1.15em;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  max-height: 30%;
  min-width: 30%;
  box-sizing: content-box;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #00caa8;
  font-size: 1em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.burgerVisible {
  visibility: hidden;
}

.bm-menu-wrap {
  max-width: 40%;
  margin-top: 50px;
}

.menu-items {
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1em;
  align-self: center;
  justify-self: center;
}
.side-menu-container {
  flex-wrap: wrap;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .burgerVisible {
    visibility: visible;
  }
  .menuVisible {
    visibility: hidden;
  }
}
