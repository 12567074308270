@import "../../styles/variables";

.container {
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  margin-top: $ml;
  display: flex;
  flex-direction: row;
  height: 240px;
  background-color: $dark;
  padding: $mm;
  width: 100vw;
}
.container img {
  width: auto;
  height: 50px;
}

.whatssUpBtn {
  border-radius: 80px;
  height: 60px;
  width: 60px;
  background-color: #33cc66;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
}

.whatssUpBtn img {
  max-width: 40px;
  max-height: 40px;
}

.whatssUpBtn:hover {
  background-color: #4ceb15;
}

.mobileContainer {
  position: fixed;
  bottom: 5em;
  right: 1.6em;
}

.contactBox {
  width: 200px;
  display: flex;
  flex-direction: column;
}
.logoBox {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.whatssUpBtnLarge {
  border-radius: 80px;
  height: 55px;
  width: 230px;
  background-color: #33cc66;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: -13px 14px 15px -13px rgba(0, 0, 0, 0.49);
  margin-top: 1em;
  text-decoration: none;
}
.whatssUpBtnLarge:hover {
  background-color: #4ceb15;
}
